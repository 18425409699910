import { Map } from 'immutable';
import { isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';
import FormSection from '../../forms/FormSection';
import FormField from '../../forms/FormField';

const draftRenderer = new Renderer(defaultBlocks);

const MediaOutletDetailsSection = ({ className, application, section }) => {
  // NOTE: This is a huge design flaw to determine if the promoter is 'Goldenvoice', but it works without requiring much re-design.
  const isGoldenvoice = application
    .getIn(['event', 'festival', 'promoter', 'name'])
    .startsWith('Goldenvoice');

  const festivalName = application.getIn(['event', 'festival', 'name']);
  return (
    <section className={className}>
      <header>
        <div className={`${className}--title`}>Media Outlet Details</div>
      </header>

      <article>
        <div className={`${className}--desc`}>
          <div
            className="draftjs-output"
            dangerouslySetInnerHTML={{
              __html: draftRenderer.convertRawToHTML(
                section.get('content').toJS(),
              ),
            }}
          />
        </div>
        <FormSection name="details">
          <FormField
            name="publicationLink"
            component={ReduxFormsField}
            label="Publication Link / Website Outlet Type"
            placeholder="Enter Publication Link / Website Outlet Type"
            required
            validate={isRequired('Publication Link / Website Outlet Type')}
          >
            <input type="text" />
          </FormField>
          <FormField
            name="uniqueVisitors"
            component={ReduxFormsField}
            label="Unique Visitors Per Month"
            placeholder="Enter Unique Visitors Per Month"
            required
            validate={isRequired('Unique Visitors Per Month')}
          >
            <input type="text" />
          </FormField>
          <FormField
            name="socialMediaLink"
            component={ReduxFormsField}
            label="Social Media Links"
            placeholder="Enter Social Media Links"
          >
            <input type="text" />
          </FormField>
          <FormField
            name="festivalCoverageLink"
            component={ReduxFormsField}
            label="Please Provide a Link to Some Past Festival Coverage"
            placeholder="Enter Link to Past Festival Coverage"
            required
            validate={isRequired('Link to Past Festival Coverage')}
          >
            <input type="text" />
          </FormField>
          <FormField
            name="hasPastAccreditation"
            component={ReduxFormsField}
            label={`Have You Been Granted Press Accreditation To A ${
              isGoldenvoice ? 'Goldenvoice' : festivalName
            } Festival In The Past?`}
            required
            validate={isRequired('Past Accreditation')}
          >
            <select>
              <option value="">Select One</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </FormField>
          <FormField
            name="hasPurchasedPasses"
            component={ReduxFormsField}
            label="Have You Already Purchased Passes For The Festival?"
            required
            validate={isRequired('Purchased Passes')}
          >
            <select>
              <option value="">Select One</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </FormField>
        </FormSection>
      </article>
    </section>
  );
};

MediaOutletDetailsSection.propTypes = {
  className: PropTypes.string.isRequired,
  application: PropTypes.instanceOf(Map).isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default MediaOutletDetailsSection;
